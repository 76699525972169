<template>
  <log
    @goPage="goPage"
    @setStartDate="setStartDate"
    @setEndDate="setEndDate"
    :list="list"
    :logData="logData"
    :pageInfo="pageInfo"
    :startDate="startDate"
    :endDate="endDate"
  />
</template>
<script>
import log from "@/components/mypage/pc/log.vue";
import { apiLoginLog } from "@/api/user";
export default {
  name: "LoginLog",
  emits: ["getUserDetail", "modalOpen"],
  props: ["userDetail"],
  components: {
    log,
  },
  data() {
    return {
      logData: {
        tr: ["일시", "접속IP", "접속기기"],
        td: ["lgnDt", "ip", "dvc"],
      },
      list: [],
      model: {
        rowCnt: 20,
        page: 1,
        searchRst: 0,
        searchStartDt: "",
        searchEndDt: "",
      },
      pageInfo: {
        page: 1,
        rowCnt: 20,
        totalPageCnt: 1,
      },
      startDate: this.getDateStr(new Date().setDate(1), "yyyy-MM-dd"),
      endDate: this.getDateStr(new Date(), "yyyy-MM-dd"),
      searchMonth: this.getDateStr(new Date(), "yyyy-MM"),
    };
  },
  watch: {
    searchMonth: {
      handler(value) {
        console.log(value, "value");
        const fullDate = new Date(value);
        const year = fullDate.getFullYear();
        const month = fullDate.getMonth();
        // console.log(month);
        this.startDate = this.getDateStr(
          new Date(year, month, 1),
          "yyyy-MM-dd"
        );
        this.endDate = this.getDateStr(
          new Date(year, month + 1, 0),
          "yyyy-MM-dd"
        );
      },
    },
    startDate: {
      async handler() {
        await this.getLoginLog(1);
      },
    },
    endDate: {
      async handler() {
        await this.getLoginLog(1);
      },
    },
    "model.searchRst": {
      async handler() {
        await this.getLoginLog(1);
      },
    },
  },
  methods: {
    nextMonth() {
      this.searchMonth = new Date(this.searchMonth);
      const month = this.searchMonth.getMonth();
      this.searchMonth = this.searchMonth.setMonth(month + 1);
    },
    prevMonth() {
      this.searchMonth = new Date(this.searchMonth);
      const month = this.searchMonth.getMonth();
      this.searchMonth = this.searchMonth.setMonth(month - 1);
    },
    setMonth(month) {
      // console.log(month)
      this.searchMonth = month;
    },
    setStartDate(date) {
      this.startDate = this.getDateStr(date, "yyyy-MM-dd");
    },
    setEndDate(date) {
      this.endDate = this.getDateStr(date, "yyyy-MM-dd");
    },
    onSelected(value) {
      this.model.searchRst = value;
    },
    // 로그인 기록 조회
    async getLoginLog(page) {
      if (page) {
        this.model.page = page;
      }
      const req = this.model;
      this.model.searchStartDt = this.startDate;
      this.model.searchEndDt = this.endDate;
      // console.log(req);
      const res = await apiLoginLog(req);
      if (res.list) {
        this.pageInfo = res.pageInfo;
        this.list = res.list;
      }
    },
    async goPage(page) {
      await this.getLoginLog(page);
    },
    async search() {
      await this.getLoginLog();
    },
  },
  async created() {
    await this.getLoginLog();
  },
};
</script>
<style scoped></style>
